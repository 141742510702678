<template>
  <div class="container text-white" style="padding-top:17%">
        <div class="d-flex">
            <h1 class="ms-auto">
                {{config.sex=="male" ? 'Ποιός' : 'Ποια'}}
            </h1>
            <multi-select
                v-model="selectedName"
                value-prop="name"
                track-by="name"
                label="name"
                :object="false"
                style="max-width:20em;"
                class="bg-dark mx-3"
                placeholder="Επιλέξτε Όνομα"
                no-options-text="Δεν βρισκουμε ονοματα :("
                :can-deselect="false"
                :options="names">
                <!--searchable="true"-->
                <template v-slot:singlelabel="{ value }">
                    <div class="multiselect-single-label">
                      {{ value.name }}
                    </div>
                </template>
                <template v-slot:option="{ option }">
                    <span class="text-dark">{{option.name}}</span>
                </template>
            </multi-select>
            <h1 class="me-auto">
                ;
            </h1>

        </div>
        <div class="row mt-5 text-light">
            <div class="col-12 col-md-6 text-center text-md-end">
                <div class="btn-group" role="group">
                    <button class="btn px-3" :class="config.sex=='male' ? 'btn-light' : 'btn-outline-light'" @click="changeSex('male')">
                        👨‍ Ανδρας
                    </button>
                    <button class="btn px-3" :class="config.sex=='female' ? 'btn-light' : 'btn-outline-light'" @click="changeSex('female')">
                        👩‍ Γυναίκα
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 text-center text-md-start mt-3 mt-md-0">
                <div class="btn-group ms-0 ms-md-5" role="group">
                    <button class="btn px-3" :class="config.name=='first' ? 'btn-light' : 'btn-outline-light'" @click="changeNameType('first')">
                        Μικρό
                    </button>
                    <button class="btn px-2" :class="config.name=='last' ? 'btn-light' : 'btn-outline-light'" @click="changeNameType('last')">
                        Επίθετο
                    </button>
                </div>
            </div>
        </div>

        <div class="row mt-5" v-if="ataka.length">
            <div v-if="!Array.isArray(ataka)" class="col-12 h1 text-warning text-center">
                {{ataka}}!
            </div>
            <div v-else v-for="(at, i) in ataka" :key="i" class="col-12 h1 text-warning text-center mb-4">
                {{at}}!
            </div>
        </div>

        <div class="row mt-auto text-center fixed-bottom mb-3 mt-5 pt-5 mx-3">
            <router-link to="/submit" class="link-light text-decoration-none">Έχετε άλλα; Κάντε κλικ εδώ για να υποβάλετε τη δική σας ατάκα!</router-link>
            <!--<router-link to="/submit" class="link-light text-decoration-none">Έχετε νέα ιδέα; Υποβάλετε τη δική σας ατάκα! </router-link>-->
        </div>
    </div>
</template>

<script>
import DB from '../db.js';
import MultiSelect from '@vueform/multiselect'
export default {
    components: { MultiSelect },
    data() {
        return {
            selectedName: "",
            config: {
                sex: "male",
                name: "first"
            }
        }
    },
    methods : {
        changeSex(sex){
            this.selectedName= "";
            this.config.sex=sex;
        },
        changeNameType(type){
            this.selectedName= "";
            this.config.name=type;
        }
    },
    computed:{
        names(){
            return DB.filter(db=>db.sex==this.config.sex && db.name==this.config.name)[0].contents.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        ataka(){
            return this.names.find(n=>n.name==this.selectedName) ? this.names.find(n=>n.name==this.selectedName).line : ''
        }
    }

}
</script>

<style scoped>
    @import '~@vueform/multiselect/themes/default.css'
</style>

<style>
body {
    background-color: #212529 !important;
}
</style>
