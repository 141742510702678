import { createApp } from 'vue'
import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue'

import Home from './components/HomePage.vue'
import Submit from './components/SubmitPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/submit', component: Submit },
  ]
})

const app = createApp(App)
app.use(router)
app.mount('#app')
