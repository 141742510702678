<template>
  <div class="container text-white mt-4">
        <div class="row text-start mb-5">
            <a href="/" class="link-light text-decoration-none mb-3">
                <img src="/favicon.png" class="me-2 mb-1" height="20">Aρχική
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-xl-8 col-lg-9 col-md-10 ">
                <h1 class="text-center mb-5">Υποβάλετε τη δική σας ατάκα:</h1>

                <div class="row">
                    <div class="mb-4 col-6">
                        <label class="pb-2">Φύλο:</label>
                        <select class="form-select bg-dark text-white" v-model="sex" @change="clearAlert">
                            <option value="male">👨‍ Ανδρας</option>
                            <option value="female">👩‍ Γυναίκα</option>
                        </select>
                    </div>
                    <div class="mb-4 col-6">
                        <label class="pb-2">Τύπου ονόματος:</label>
                        <select class="form-select bg-dark text-white" v-model="nameType" @change="clearAlert">
                            <option value="first">Μικρό</option>
                            <option value="last">Επίθετο</option>
                        </select>
                    </div>
                    <div class="mb-4 col-12">
                        <label class="pb-2">Ονομα:</label>
                        <input type="text" class="form-control bg-dark text-white" placeholder="Ονομα" v-model="name" @change="clearAlert"/>
                    </div>
                    <div class="mb-4 col-12">
                        <label class="pb-2">Ατάκα:</label>
                        <textarea type="text" class="form-control bg-dark text-white" placeholder="Ατάκα" v-model="line" @change="clearAlert"/>
                    </div>
                    <div class="col-12" v-if="submitAlert.msg.length">
                        <div class="alert text-center" :class="`alert-${submitAlert.type}`" role="alert">
                            {{submitAlert.msg}}
                        </div>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-outline-success w-100" @click="submit">
                            Υποβολή
                            <div v-if="isSubmitting" class="spinner-border spinner-border-sm ms-2" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            sex: null,
            nameType: null,
            name: null,
            line: null,
            isSubmitting: false,
            submitAlert: {
                type: 'success',
                msg: ''
            }
        }
    },
    methods : {
        clearForm(){
            this.sex = null;
            this.nameType = null;
            this.name = null;
            this.line = null;
        },
        clearAlert(){
            this.submitAlert.msg=""
        },
        submit(){
            const postData = {
                sex: this.sex,
                nameType: this.nameType,
                name: this.name,
                line: this.line
            }

            this.isSubmitting = true;
            axios.post ('https://3p5w37fn76.execute-api.eu-central-1.amazonaws.com/v1', postData).then((response) => {
               console.log ("response", response.data)

               this.clearForm();
               this.submitAlert.msg="Σας ευχαριστούμε";
               this.isSubmitting = false;
            }).catch(function (error) {
                console.log("error", error);
                this.isSubmitting = false;
                this.submitAlert.msg ="Λυπούμαστε, προέκυψε σφάλμα"
            })

        }
    },
    computed:{

    }

}
</script>
<style scoped>
    .btn-outline-success {
        border-color: #52D273;
        color: #52D273;
    }
    .btn-outline-success:hover {
        color: #fff;
        background-color: #52D273;
    }
</style>
