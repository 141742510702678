<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scoped>
    @import'~bootstrap/dist/css/bootstrap.css'
</style>
