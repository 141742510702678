export default [
    {
        "sex": "male",
        "name": "first",
        "contents": [
            {
                "name": "Andreas",
                "line": "Autos pou sou gemizi to kolo kreas"
            },
            {
                "name": "Γιαννης",
                "line": "Αυτος που τον πεταει και τον πιανεις"
            },
            {
                "name": "Ηγουμενος",
                "line": "Αυτός που τον γαμαγε ο προηγούμενος"
            },
            {
                "name": "Hlia",
                "line": "Ton poutso mou ton karaxaria"
            },
            {
                "name": "Ερικος",
                "line": "Αυτός που σε πηδάει αδιακρίτως"
            },
            {
                "name": "Nikos",
                "line": "Ο γαμικος"
            },
             {
                "name": "Μιχάλης",
                "line": "Ο πούτσος μου ο μακρυμαλλης"
            },
            {
                "name": "Nektario",
                "line": "Autos pou se gamah mexri methaurio"
            },
            {
                "name": "Sotiri",
                "line": "Ton poutso mou ton trexantiri"
            },
            {
                "name": "Vasilisis",
                "line": "Ton poutso mou ton ag. Vasili"
            },
            {
                "name": "Simos",
                "line": "Autos pou se gamah episimos"
            },
            {
                "name": "Felipo",
                "line": "Autos pou se gamah otan lipo"
            },
            {
                "name": "Orestis",
                "line": "O poutsos mou o pyrosbestis"
            },
            {
                "name": "Anestis",
                "line": "O poutsos mou o pyrosbestis"
            },
            {
                "name": "Φάνης",
                "line": "Αυτός που σε γαμάει μέχρι να πεθάνεις"
            },
            {
                "name": "Θανάσης",
                "line": "Αυτός που σε μπουκώνει ώσπου να σκάσεις"
            },
            {
                "name": "Περικλης",
                "line": "Ο πουτσος μου ο μερακλής"
            },
            {
                "name": "ΝΩΝΤΑΣ",
                "line": "Αυτός που σε πήγαινε γαμιώντας"
            },
            {
                "name": "Σπυρος",
                "line": "Εκείνος που στον έδινε με κύρος"
            },
            {
                "name": "Κίμωνας",
                "line": "Ο πουτσος μου ο επιστήμονας"
            }

        ]
    },
    {
        "sex": "male",
        "name": "last",
        "contents": [
            {
                "name": "Athansiou",
                "line": "Autos pou se gamouse sti 2h gimnasiou"
            },
            {
                "name": "Morales",
                "line": "Αυτος που σε γαμουσε τις προάλλες"
            },
            {
                "name": "Μιλήσης",
                "line": "Αυτός που με τη πούτσα του σου έβρισκε τις λύσεις"
            },
            {
                "name": "Trikogia",
                "line": "Autos pou sou gemizi to kolo soya"
            }
        ]
    },
    {
        "sex": "female",
        "name": "first",
        "contents": [
            {
                "name": "Αθανασία",
                "line": "Αυτή που σε γλεντούσε ως τη δευτέρα παρουσία"
            },
            {
                "name": "Elenh",
                "line": [
                    "H poutsa mou h kablomeni",
                    "H poutsa mou h peritomeni"
                ]
            },
            {
                "name": "Eυα",
                "line": "Στην πουτσα μου ανεβα"
            },
            {
                "name": "Ματίνα",
                "line": "Η Ματίνα που εχει ενα πάτο 'NA'"
            },
            {
                "name": "Λουκία",
                "line": "Τραβιέται μόνη της η μαλακία"
            },
        ]
    },
    {
        "sex": "female",
        "name": "last",
        "contents": []
    }
]
